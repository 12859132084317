import React from "react"
import { Layout, SEO } from "../components"
import { ReactComponent as Logo } from "../../static/logos/main-logo.svg"
import Slider from "react-slick"
import { graphql } from "gatsby"
import Img from "gatsby-image"

export default function home({ data }) {
  const images = data.allFile.edges
  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 1000,
    autoplaySpeed: 7500,
    draggable: false,
    cssEase: "linear",
    arrows: false,
    pauseOnHover: false
  }

  return (
    <Layout footerless={true} isNavSticky={true} isInverted={true} isIndex={true}>
      <SEO title="Inicio" />
      <div className="home__slider">
        <Logo className="home__logo" />
        <Slider {...settings}>
          <div className="home__slider-item">
            <Img
              fluid={images[1].node.childImageSharp.fluid}
              alt="L'atelier Des Fleurs Slide"
              className="home__slider-image"
            />
          </div>
          <div className="home__slider-item">
            <Img
              fluid={images[3].node.childImageSharp.fluid}
              alt="L'atelier Des Fleurs Slide"
              className="home__slider-image"
            />
          </div>
          <div className="home__slider-item">
            <Img
              fluid={images[2].node.childImageSharp.fluid}
              alt="L'atelier Des Fleurs Slide"
              className="home__slider-image"
            />
          </div>
          <div className="home__slider-item">
            <Img
              fluid={images[0].node.childImageSharp.fluid}
              alt="L'atelier Des Fleurs Slide"
              className="home__slider-image"
            />
          </div>
          <div className="home__slider-item">
            <Img
              fluid={images[4].node.childImageSharp.fluid}
              alt="L'atelier Des Fleurs Slide"
              className="home__slider-image"
            />
          </div>
        </Slider>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query GET_INDEX_IMAGES {
    allFile(
      filter: { extension: { regex: "/(jpg)|(png)|(tif)|(tiff)|(webp)|(jpeg)/" }, absolutePath: { regex: "/index/" } }
    ) {
      edges {
        node {
          name
          childImageSharp {
            fluid(maxWidth: 915, quality: 70) {
              originalName
              src
              srcSet
              aspectRatio
              sizes
            }
          }
        }
      }
    }
  }
`
